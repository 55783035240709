import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const MatrixContainer = styled.div`
  display: inline-block;
  width: 1100px;
  @media (max-width: 1300px) {
    width: 60%;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 80%;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
  }
`

export const Section = styled.div`
  text-align: center;
  padding: 130px 0px;
  background-color: #dbe9f2;
  @media (max-width: ${breakpoints.l}px) {
    padding: 80px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 60px 0px;
  }
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 10px;
  max-width: 1200px;
  margin: auto;
  padding: 50px;
`
export const ImageContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  margin: auto;
`
export const Icon = styled.div`
  width: 30px;
`
export const FeatureItem = styled.div`
  border-radius: 16px;
  background: #fff;
  @media (max-width: 390px) {
    border-radius: 14px;
  }
`
export const Row = styled.tr`
  background: ${(props) => (props.white ? '#fff' : props.last ? '#dbe9f2' : '#F2F2F2')};
`

export const Cell = styled.td`
  padding: 25px 30px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 28px 0px 28px 15px;
  }
  @media (max-width: 390px) {
    padding: 24px 0px 24px 12px;
  }
`
export const CheckCell = styled.td`
  padding: 16px 0px;
  border-left: ${(props) => (props.blue ? '3px solid #dbe9f2' : 'none')};
  border-right: ${(props) => (props.blue ? '3px solid #dbe9f2' : 'none')};
  border-top-right-radius: ${(props) => (props.borderRadius ? '16px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.borderRadius ? '16px' : '0px')};
  @media (max-width: ${breakpoints.s}px) {
    border-left: none;
  }
`
export const HeaderCell = styled.th``
export const HeaderCellText = styled.th`
  font-size: 20px;
  font-family: 'FilsonPro';
  width: 120px;
  line-height: 18px;
  @media (max-width: ${breakpoints.m}px) {
    width: 90px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 70px;
  }
  @media (max-width: 390px) {
    width: 50px;
  }
`

export const Title = styled.h2`
  color: black;
  font-size: 20px;
  line-height: 24px;
  font-family: 'FilsonPro';
  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
    line-height: 18px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 18px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
`
export const Subheader = styled.p`
  font-family: 'FilsonPro';
  font-size: 40px;
  margin: 0px;
  text-align: left;
  color: black;
  margin-bottom: -30px;
  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 0px;
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
`
export const Checkmark = styled.object`
  align-self: center;
  width: 30px;
  @media (max-width: ${breakpoints.m}px) {
    width: 20px;
  }
`
export const FeatureName = styled.p`
  color: black;
  font-size: 20px;
  line-height: 36px;
  margin: 0px;
  text-align: left;
  @media (max-width: 1350px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    font-size: 13px;
  }
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`
