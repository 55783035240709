export const COMPETITOR_COMPARISON = [
  {
    text: 'Available now',
    them: false,
  },
  {
    text: 'Counter stand (15" and 10")',
    them: false,
  },
  {
    text: 'Wall mount (Max and 15")',
    them: true,
  },
  {
    text: 'Multiple sizes',
    them: false,
  },
  {
    text: 'Subscription-free features (Chore Chart, grocery list)',
    them: false,
  },
  {
    text: 'Free software updates',
    them: false,
  },
  {
    text: 'Phone support',
    them: false,
  },
  {
    text: '120 day return policy',
    them: false,
  },
]
