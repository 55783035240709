import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Header } from 'src/utils/styles/calendar'

export const Section = styled.div`
  text-align: center;
  margin: 0px auto 100px;
  width: 90%;
  @media (max-width: ${breakpoints.m}px) {
    margin: 40px auto;
  }
`
export const ManifestoSection = styled(Section)`
  width: 100%;
  margin: 0px auto;
  #manifesto {
    width: 800px;
  }
  @media (max-width: 1600px) {
    #manifesto {
      width: 650px;
    }
  }
  @media (max-width: 1450px) {
    #manifesto {
      width: 550px;
    }
  }
  @media (max-width: ${breakpoints.xl}px) {
    #manifesto {
      width: 450px;
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 60%;
    #manifesto {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    #manifesto {
      width: 100%;
    }
  }
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 80px;
  max-width: 1200px;
  margin: auto;
  padding: 50px 0px 0px;
  #ZDNet {
    width: 150px;
  }
  #wallStreet {
    width: 400px;
  }
  #apartment {
    width: 405px;
  }
  #today {
    width: 330px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    #ZDNet {
      width: 125px;
    }
    #wallStreet {
      width: 290px;
    }
    #apartment {
      width: 335px;
    }
    #today {
      width: 210px;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    margin-top: 50px;
    grid-template-columns: auto;
    gap: 50px;
    padding: 0px 0px 20px;
    #ZDNet {
      width: 95px;
    }
    #wallStreet {
      width: 230px;
    }
    #apartment {
      width: 235px;
    }
    #today {
      width: 210px;
    }
  }
`
export const ImageContainer = styled.div`
  margin: auto;
  > div:first-child {
    display: block;
  }
  > div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.s}px) {
    > div:first-child {
      display: none;
    }
    > div:nth-child(2) {
      display: block;
    }
  }
`
export const LogoContainer = styled.div`
  margin: auto;
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 20px;
  width: 400px;
  text-align: center;
  margin: 40px auto 0px;
  color: black;
  line-height: 28px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 24px auto 0px;
    font-size: 16px;
  }
`
export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: auto;
    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
  }
`
export const Column = styled.div``
export const TextContainer = styled.div`
  padding: 100px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 30px 80px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 40px 0px 80px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 50px 0px 60px;
    width: 90%;
    margin: auto;
  }
`
export const ManifestoHeader = styled(Header)`
  margin: 0px;
  text-align: left;
  color: black;
  font-size: 40px;
  span {
    font-family: 'FilsonProRegularItalic';
  }
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    font-size: 24px;
  }
`
export const ManifestoText = styled.p`
  text-align: left;
  margin: 0px;
  font-size: 18px;
  color: black;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
`
export const Title = styled(Header)`
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 0px;
  }
`
