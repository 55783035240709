import React from 'react'
import CountUp from 'react-countup'
import CheckMark from 'src/images/calendar/checkbox-round.svg'
import { graphql, useStaticQuery } from 'gatsby'

import {
  Section,
  Container,
  ChoreCountContainer,
  Header,
  Text,
  ImageContainer,
  Count,
  CalendarSyncsContainer,
  SubTitle,
  IconsContainer,
  Card,
  IconText,
  Icon,
} from './styles'

const smoothEasing = (t, b, c, d) => (c * t) / d + b
const Counter = () => {
  const { google, outlook, apple, cozi, yahoo } = useStaticQuery(graphql`
    query CalendarHeroQuery {
      google: file(relativePath: { eq: "calendar/syncs/google.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      outlook: file(relativePath: { eq: "calendar/syncs/outlook.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      apple: file(relativePath: { eq: "calendar/syncs/apple.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cozi: file(relativePath: { eq: "calendar/syncs/cozi.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      yahoo: file(relativePath: { eq: "calendar/syncs/yahoo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const CALENDAR_SYNCS_CONSTANTS = [
    {
      image: google,
      alt: 'Google Calendar Logo',
      text: `Google`,
    },
    {
      image: outlook,
      alt: 'Outlook Calendar Logo',
      text: 'Outlook',
    },
    {
      image: apple,
      alt: 'Apple Calendar Logo',
      text: 'Apple',
    },
    {
      image: cozi,
      alt: 'Cozi Calendar Logo',
      text: 'Cozi',
    },
    {
      image: yahoo,
      alt: 'Yahoo Calendar Logo',
      text: 'Yahoo',
    },
  ]
  return (
    <Section>
      <CalendarSyncsContainer>
        <SubTitle>Seamlessly auto-syncs with:</SubTitle>
        <IconsContainer>
          {CALENDAR_SYNCS_CONSTANTS.map((calendar, index) => (
            <Card key={index}>
              <Icon image={calendar.image.childImageSharp.gatsbyImageData} alt={calendar.alt} />
              <IconText>{calendar.text}</IconText>
            </Card>
          ))}
        </IconsContainer>
      </CalendarSyncsContainer>
      <Container>
        <Header>Bringing families together, one chore at a time.</Header>
        <ChoreCountContainer>
          <ImageContainer type="image/svg+xml" data={CheckMark} aria-label="Checkmark">
            svg-animation
          </ImageContainer>
          <CountUp
            delay={0}
            start={11308880}
            end={11371181}
            duration={7}
            separator=","
            decimals={0}
            useEasing
            easingFn={smoothEasing}
            useGrouping
            decimal=","
            preserveValue
          >
            {({ countUpRef }) => <Count ref={countUpRef} />}
          </CountUp>
        </ChoreCountContainer>
        <Text>Chores Completed</Text>
      </Container>
    </Section>
  )
}

export default Counter
