import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import first from '../../images/calendar/guarantee-web-down.svg'
import second from '../../images/calendar/guarantee-web-up.svg'
import firstMobile from '../../images/calendar/guarantee-mobile-up.svg'
import secondMobile from '../../images/calendar/guarantee-mobile-down.svg'

export const Section = styled.div`
  background-image: url(${(props) => (props.first ? first : second)});
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 140px 0px;
  opacity: 95%;
  @media (max-width: ${breakpoints.l}px) {
    padding: 100px 0px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 60px 0px;
    background-image: url(${(props) => (props.first ? secondMobile : firstMobile)});
  }
`
export const Container = styled.div`
  width: 615px;
  margin: auto;
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 90%;
  }
`
export const Header = styled.h1`
  font-family: 'FilsonPro';
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 20px;
  color: black;
  margin: 0px;
  line-height: 28px;
  margin: 10px 0px 50px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    margin: 0px 0px 40px;
  }
`
