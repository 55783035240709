export const CAL_MAX_FEATURES = [
  {
    title: 'All-in-One Organization',
    text: 'Stay effortlessly organized with a touchscreen display that shows all your events, chores, dinner plans, and tasks.',
    alt: 'Touchscreen digital calendar display showing organized events, chores, and dinner plans for multiple dates, including family tasks and scheduled activities for March 22, April 7, and April 12',
  },
  {
    title: 'Calendar Auto-Syncing',
    text: 'Seamlessly connect every calendar from multiple personal devices for total schedule clarity.',
    alt: 'Skylight Calendar displaying a synced schedule from multiple platforms including Google, Outlook, Yahoo, and Cozi, showcasing seamless integration for total schedule clarity',
  },
  {
    title: 'Anytime, Anywhere Planning',
    text: 'With the free Skylight app, you can easily update your schedule, chore chart, lists, and more on the go.',
    alt: 'A woman using the Skylight app to manage tasks such as walking the dog and answering emails',
  },
  {
    title: 'Interactive Chore Chart',
    text: 'Manage daily chores progress while also encouraging positive habits and building independence.',
    alt: 'Interactive chore chart displaying daily tasks with checkmarks for two out of four chores completed, homework and feed pets.',
  },
  {
    title: 'Easy Dinner Planning',
    text: 'End the daily dinner debate! Add your dinner plans right to the Calendar view for everyone to see.',
    alt: `Family adding dinner plans like Lasagna and BBQ Shrimp to the Skylight Calendar's Thursday view.`,
  },
  {
    title: 'Custom Lists',
    text: 'Grocery lists, packing lists, wish lists — you can create, edit, and color code them right from the Calendar or on the go with the app.',
    alt: 'Custom lists for groceries, to-dos, and birthday wish lists displayed on a digital screen, showcasing the ability to create, edit, and color-code lists using the Skylight app',
  },
]
