import React from 'react'
import PropTypes from 'prop-types'
import { Header } from 'src/utils/styles/calendar'
import { Section, Container, Text } from './styles'
import { CAL_BUY_LINK } from '../../utils/constants'
import { CenteredMobile, BuyNowLink } from '../CalMaxHero/styles'

const CalendarGuarantee = ({ first, title, description, ctaText, ctaLink }) => (
  <Section first={first}>
    <Container>
      <Header>{title || '120-day free returns'}</Header>
      <Text>
        {description ||
          `Your happiness is our happiness. If you aren't 100% thrilled with your purchase, return it
        for a full refund. No questions asked.`}
      </Text>
      <CenteredMobile isCalendarNY={false}>
        <BuyNowLink to={ctaLink || CAL_BUY_LINK} isCalendarNY={false}>
          {ctaText || `Buy Now`}
        </BuyNowLink>
      </CenteredMobile>
    </Container>
  </Section>
)

CalendarGuarantee.propTypes = {
  first: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

export default CalendarGuarantee
